import React, { useState } from "react";
import { TWclassNames } from "./Div";
import { isVisible } from "@testing-library/user-event/dist/utils";

export const ResourceLoading = ({
  size,
  loaderClass,
  baseClass,
  position,
  isVisible,
  label,
}) => {
  if (!isVisible) return null; // Render nothing if not visible

  if (size === "base") {
    return (
      <div
        className={TWclassNames(
          "mt-10 mb-10 top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center transition-opacity duration-300 ease-in-out",
          isVisible ? "opacity-100" : "opacity-0"
        )}
      >
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4 animate-spin"></div>
        <h2 className="text-center text-black text-l font-semibold">
          {label ? label : "Loading..."}
        </h2>
      </div>
    );
  }

  return (
    <div
      className={TWclassNames(
        baseClass,
        "transition-opacity duration-300 ease-in-out",
        isVisible ? "opacity-100" : "opacity-0"
      )}
    >
      <div
        className={TWclassNames(
          "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10 animate-spin",
          loaderClass,
          position
        )}
      ></div>
    </div>
  );
};

ResourceLoading.defaultProps = {
  isVisible: true,
  size: "base",
  position: "relative",
  loaderClass: "",
  baseClass:
    "top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden opacity-75 bg-gray-50 flex flex-col items-center justify-center",
};
