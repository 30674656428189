import { useSelector } from "react-redux";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { Form, Formik } from "formik";
import { PMField } from "../../../components/utils/PMField";
import toast from "react-hot-toast";
import { dispatchWithToast } from "../../../components/Utils";
import { updateUserAsync } from "../../../slices/user";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import PMListbox from "../../../components/utils/PMListbox";
import {
  CheckBadgeIcon,
  MapPinIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

export const UserProfile = (props) => {
  const currentUser = useSelector((state) => state.session.currentUser);

  const dispatch = useDispatch();

  const mapsServices = [
    {
      name: "prefferd_maps_service",
      value: "google_maps",
      label: "Google Maps",
      icon: CheckBadgeIcon,
    },
    {
      name: "prefferd_maps_service",
      value: "apple_aps",
      label: "Apple Maps",
      icon: MapPinIcon,
    },
  ];

  const handleListboxChange = (e) => {
    dispatch(updateUserAsync({ ...currentUser, [e.name]: e.value }));
  };

  function UserForm({ disabled }) {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          id: currentUser?.id,
          name: currentUser?.name,
          email: currentUser?.email,
          submit: null,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(),
          // .required(t("Email is required")),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              dispatch(updateUserAsync(values))
              // (data) => onRefresh(data),
              // onClose
            );
          } catch (err) {
            toast.error("Something went wrong...");
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            // helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10 ">
              <div className="col-span-6 sm:col-span-6">
                <PMField
                  name="name"
                  type="text"
                  label={"Navn"}
                  {...formik}
                  disabled={disabled}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <PMField
                  name="email"
                  type="text"
                  label={"E-post"}
                  {...formik}
                  disabled={disabled}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="address"
                  type="text"
                  label={"Adresse"}
                  {...formik}
                  disabled={disabled}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <PMField
                  name="address2"
                  type="text"
                  label={"Adresse 2"}
                  {...formik}
                  disabled={disabled}
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <PMField
                  name="postal"
                  type="text"
                  label={"Postnr."}
                  {...formik}
                  disabled={disabled}
                />
              </div>

              <div className="col-span-6 sm:col-span-2">
                <PMField
                  name="place"
                  type="text"
                  label={"Sted"}
                  {...formik}
                  disabled={disabled}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <PMField
                  name="prefferd_maps_service"
                  type="text"
                  label={"Ønsket karttjeneste"}
                  {...formik}
                />

                <PMListbox
                  name="prefferd_maps_service"
                  label={"Ønsket karttjeneste"}
                  options={mapsServices}
                  selected={currentUser?.prefferd_maps_service}
                  onChange={handleListboxChange}
                />
              </div>
            </div>
            <div className="border-top border-2 border-blue-400 mt-10" />
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <>
      <DashboardHeader
        subtitle={currentUser.name}
        actions={[]}
        title={"Brukerprofil"}
        // meta={headerMeta}
        // mainButton={{
        //   label: "New",
        //   action: () => setDeviationNewDialogOpen(true),
        //   path: null,
        //   color: "",
        //   icon: PlusIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3 bg-white shadow rounded-xl px-5 py-5">
            <UserForm disabled />
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};
