import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { mapErrors } from "../../../Utils";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { useDispatch } from "react-redux";
import PMListboxFormik from "../../../utils/PMListboxFormik";
import { getPdfReport, sendReport } from "../../../../api/service/report";
import { PMField } from "../../../utils/PMField";
import { useSelector } from "react-redux";
import { QuillEditor } from "../../../utils/quill-editor";
import PMSwitch from "../../../utils/PMSwitch";

export const ReportSendDialog = (props) => {
  const { onLoading, onClose, open, isLoading, report } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [documentUrl, setDocumentUrl] = useState(null);

  const initialValues = {
    id: report?.id,
    sendReport: true,
    printReportType: "",
    email: "",
    name: "",
    allow_review: false,
    submit: null,
  };

  const currentUser = useSelector((state) => state.session.currentUser);

  const handleSend = async (payload) => {
    return await sendReport(payload);
  };

  const handleRecciverChange = (recciver, formik) => {
    formik.setFieldValue("email", recciver?.email);
    formik.setFieldValue("name", recciver?.name);
    if (recciver?.email === report?.customer?.email) {
      formik.setFieldValue("printReportType", "customer_report");
    }
  };

  const [reportType, setReportType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const fetchPdfReport = async () => {
    if (!report?.id) return false;

    const payload = {
      id: report?.id,
      params: {
        printReportType: reportType,
      },
    };
    await getPdfReport(payload)
      .then((response) => {
        setPdfUrl(response?.data?.pdf_url);
        setPdfData(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReportTypeChange = (data) => {
    setReportType(data?.value);
    if (reportType) {
      fetchPdfReport();
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("Send Report")}
                    </Dialog.Title>
                    <p className="text-red-500">
                      {t("Please note that this action cannot be undone")}
                    </p>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : !report?.id ? (
                      "Missing ID"
                    ) : (
                      <Formik
                        initialValues={initialValues}
                        onSubmit={(values, helpers) => {
                          try {
                            toast.promise(handleSend(values), {
                              loading: t("Saving...."),
                              success: (data) => {
                                if (data?.status === 500) {
                                  throw new Error("Server error");
                                }
                                if (!data?.status === 200) {
                                  throw new Error(
                                    mapErrors(data?.data?.errors)
                                  );
                                }
                                props.onClose();
                                props.onRefresh(data?.payload);
                                return t("Data was saved!");
                              },
                              error: (error) => {
                                console.log(error);
                                return t(
                                  "Something went wrong. Could not save data!"
                                );
                              },
                            });
                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                            helpers.resetForm(initialValues);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10">
                              <div className="col-span-6 sm:col-span-6">
                                <div className="flex flex-col">
                                  <span className="text-gray-400 text-sm mb-2">
                                    {t("Send to")}{" "}
                                  </span>
                                  <span className="isolate inline-flex rounded-md shadow-sm">
                                    <button
                                      onClick={() =>
                                        handleRecciverChange(
                                          {
                                            email: currentUser?.email,
                                            name: currentUser?.name,
                                          },
                                          formik
                                        )
                                      }
                                      type="button"
                                      className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                    >
                                      {t("Me")}
                                    </button>
                                    <button
                                      onClick={
                                        report?.customer?.email
                                          ? () =>
                                              handleRecciverChange(
                                                {
                                                  email:
                                                    report?.customer?.email,
                                                  name: report?.customer?.name,
                                                },
                                                formik
                                              )
                                          : () =>
                                              toast.error(
                                                "Customer Email is missing"
                                              )
                                      }
                                      type="button"
                                      className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                    >
                                      {t("Customer")}
                                    </button>
                                    <button
                                      onClick={
                                        report?.contact?.email
                                          ? () =>
                                              handleRecciverChange(
                                                {
                                                  emali: report?.contact?.email,
                                                  name: report?.contact?.name,
                                                },
                                                formik
                                              )
                                          : () =>
                                              toast.error(
                                                "Contact Email is missing"
                                              )
                                      }
                                      type="button"
                                      className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                    >
                                      {t("Contact")}
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div className="col-span-6 sm:col-span-6">
                                <PMField
                                  name="name"
                                  label={t("Name")}
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-6">
                                <PMField
                                  name="email"
                                  label={t("Email")}
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-6">
                                <PMListboxFormik
                                  label={t("Report Type")}
                                  name="printReportType"
                                  onChange={handleReportTypeChange}
                                  options={[
                                    {
                                      name: "printReportType",
                                      value: "report",
                                      label: t("Report"),
                                    },
                                    {
                                      name: "printReportType",
                                      value: "customer_report",
                                      label: t("Customer Report"),
                                    },
                                    {
                                      name: "printReportType",
                                      value: "note",
                                      label: t("Note"),
                                    },
                                  ]}
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="allow_review"
                                  label="Tillat Anmeldelse"
                                  {...formik}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-6 mb-[200px]">
                                <span className="text-sm px-1">
                                  Melding til kunde
                                </span>
                                <div className="mt-1">
                                  <QuillEditor
                                    name="message"
                                    rows={4}
                                    {...formik}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>

                              <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {t("Send")}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
