import { fetchDocument, fetchDocumentStatus } from "../../../api/document";
import imgPlaceholder from "../../../utils/img-placeholder.png";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import PdfPlaceholder from "../../../utils/pdf-placeholder.png";
import { Link } from "react-router-dom";

export function documentSrc(document, preferedType = "thumb") {
  // check if filename ends with .pdf or .PDF

  if (document?.file?.filename?.toLowerCase().endsWith(".pdf")) {
    return PdfPlaceholder;
  }

  if (preferedType === "thumb") {
    return document?.file?.thumb?.url
      ? document?.file?.thumb.url
      : imgPlaceholder;
  } else if (!document?.id) {
    return imgPlaceholder;
  } else {
    return document?.file?.url ? document?.file?.url : imgPlaceholder;
  }
}

export function DocumentImage({
  document,
  open,
  onChange,
  pathType = "quickview",
}) {
  const [documentStatus, setDocumentStatus] = useState(
    document?.id ? document?.status || "processing" : null
  );
  const [documentData, setDocumentData] = useState(document);
  const pollingIntervalRef = useRef(null); // Use useRef to store the interval ID

  // Function to poll for document status and fetch the document when needed
  const pollDocumentStatus = async () => {
    if (document?.id || documentData?.id) {
      try {
        if (
          documentStatus !== "ready" &&
          !documentData?.file?.url &&
          document?.url !== ""
        ) {
          const response = await fetchDocumentStatus(document.id);
          const data = response.data;

          if (response.status === 200) {
            setDocumentStatus(data?.status);

            if (data?.status === "ready" && document?.id !== null) {
              // Fetch the document when status is 'ready'
              const documentResponse = await fetchDocument(document.id);
              setDocumentData(documentResponse.data);
              if (onChange) {
                onChange(documentResponse.data);
              }
              // Stop polling when URL is set or modal is closed
              if (documentResponse.data?.file?.url || !open) {
                clearInterval(pollingIntervalRef.current);
              }
            }
          } else {
            console.error("Error fetching document status:", data?.message);
          }
        }
      } catch (error) {
        console.error("Error fetching document status:", error);
      }
    }
  };

  useEffect(() => {
    if (document?.id) {
      // Start polling for document status when the component mounts
      pollDocumentStatus();

      // Poll every 2 seconds (adjust as needed)
      // pollingIntervalRef.current = setInterval(pollDocumentStatus, 5000);

      // Stop after 2 tries
      let tries = 0;
      const maxTries = 2;

      pollingIntervalRef.current = setInterval(() => {
        pollDocumentStatus();
        tries++;
        if (tries >= maxTries) {
          clearInterval(pollingIntervalRef.current);
        }
      }, 2000);
    }

    // Clean up the interval when the component unmounts or the modal closes
    return () => {
      clearInterval(pollingIntervalRef.current);
    };
  }, [document, open]);

  // Function to check if the document can be displayed
  function canDisplayImage() {
    if (documentData?.id || document?.id) {
      if (documentData?.file?.thumb?.url) {
        return true;
      } else {
        if (documentStatus === "ready" || documentStatus === null) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      // Return true, so placeloader is displayed
      return true;
    }
  }

  function isPdf(document) {
    return document?.file?.filename?.toLowerCase().endsWith(".pdf");
  }

  const showAsLink = () => {
    if (pathType === "quickview" && !isPdf(document)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="w-full h-full overflow-hidden">
      {canDisplayImage() ? (
        isPdf(document) ? (
          <Link to={document?.file?.url} target="_blank">
            <img
              className="w-full h-full object-contain rounded-xl"
              src={documentSrc(documentData, "thumb")}
              alt={document?.title ? document?.title : "Image placeholder"}
            />
          </Link>
        ) : (
          <img
            className="w-full h-full object-contain rounded-xl"
            src={documentSrc(documentData, "thumb")}
            alt={document?.title ? document?.title : "Image placeholder"}
          />
        )
      ) : (
        <div className="min-h-[250px]">
          <ResourceLoading
            size="small"
            // baseClass={
            //   "top-10 mt-20 w-full z-50 overflow-hidden flex flex-col items-center justify-center"
            // }
          />
        </div>
      )}
    </div>
  );
}

// set required props
DocumentImage.propTypes = {
  document: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

// set default props
DocumentImage.defaultProps = {
  open: false,
  pathType: "quickview",
};
