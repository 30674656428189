import React, { useState, useEffect, useRef } from "react";
import { PMDialog } from "../../utils/PMDialog";
import { DocumentImage } from "../documents/utils";
import { ProjectFolderSelector } from "../../utils/moduleSelectors/projectFolderSelector";
import { PMField } from "../../utils/PMField";
import { axiosWithToast } from "../../Utils";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { updateDocuments } from "../../../api/document";
import { UploaderV3 } from "./Uploaderv3";

export const DocumentUploadSectionV3 = (props) => {
  const { module, moduleParam, multiple, version, allowedFileTypes } = props;

  const [open, setOpen] = useState(false);
  const [documents, setDocuments] = useState([]); // Fully uploaded documents
  const [uploadProgress, setUploadProgress] = useState({}); // Track progress for each file
  const [filesUploaded, setFilesUploaded] = useState([]); // Files confirmed by backend
  const [totalFiles, setTotalFiles] = useState(0); // Total files being uploaded
  const [isLoading, setIsLoading] = useState(false);
  const [allUploadsComplete, setAllUploadsComplete] = useState(false);

  const containerRef = useRef(null); // Ref for scrolling

  // Reset state when starting new uploads
  const resetUploadState = () => {
    setUploadProgress({});
    setFilesUploaded([]);
    setDocuments([]);
    setAllUploadsComplete(false);
  };

  const handleFileSelection = (selectedFileCount) => {
    console.log("Selected files:", selectedFileCount);
    setTotalFiles(selectedFileCount); // Set the total number of files being uploaded
  };

  // Update allUploadsComplete based on both progress and backend confirmations
  useEffect(() => {
    const allProgressComplete =
      totalFiles > 0 &&
      Object.keys(uploadProgress).length === totalFiles &&
      Object.values(uploadProgress).every((progress) => progress === 100);

    const allFilesConfirmed = filesUploaded.length === totalFiles;

    console.log({
      allProgressComplete,
      allFilesConfirmed,
      totalFiles,
      filesUploaded,
      uploadProgress,
    });

    if (allProgressComplete && allFilesConfirmed) {
      setIsLoading(false);
      setAllUploadsComplete(true);
    }
  }, [uploadProgress, filesUploaded, totalFiles]);

  const handleUploadStart = () => {
    resetUploadState(); // Reset everything before starting a new upload
    setIsLoading(true);
    setOpen(true);
  };

  const handleOnFilesUploaded = (uploadedFiles) => {
    setFilesUploaded((prevFilesUploaded) => {
      const uniqueFiles = [
        ...prevFilesUploaded,
        ...uploadedFiles.filter(
          (newFile) =>
            !prevFilesUploaded.some(
              (existingFile) => existingFile.id === newFile.id
            )
        ),
      ];
      return uniqueFiles;
    });

    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];

      uploadedFiles.forEach((newDoc) => {
        const exists = updatedDocuments.some(
          (existingDoc) => existingDoc.id === newDoc.id
        );
        if (!exists) {
          updatedDocuments.push(newDoc);
        }
      });

      console.log(
        "Updated Documents:",
        JSON.stringify(updatedDocuments, null, 2)
      ); // Debugging
      return JSON.parse(JSON.stringify(updatedDocuments)); // ✅ Deep clone forces React to detect changes
    });
  };

  const handleProgressChange = (progress) => {
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      ...progress,
    }));
  };

  const onItemRowChange = (e, documentId) => {
    e.preventDefault();

    setDocuments((prevDocuments) =>
      prevDocuments.map((doc) =>
        doc.id === documentId
          ? { ...doc, [e.target.name]: e.target.value }
          : doc
      )
    );
  };

  const updateDocumentAsync = () => {
    axiosWithToast(
      updateDocuments(documents),
      () => {},
      () => setOpen(false)
    );

    // Reset state after saving
    resetUploadState();

    if (props.parentCallback) {
      props.parentCallback(documents);
    }
  };

  const renderDocumentWithProgress = (id) => {
    const progress = uploadProgress[id] || 0;
    const document = documents.find((doc) => String(doc.id) === String(id)); // ✅ Ensure correct ID match

    console.log(`Rendering document: ID ${id}`, document); // Debugging

    return (
      <div
        key={id}
        className="flex flex-col gap-3 py-4 bg-gray-100 rounded-xl px-4"
      >
        {/* Progress Bar */}
        <div className="relative w-full h-2 bg-gray-200 rounded">
          <div
            className="absolute h-full bg-blue-500 rounded"
            style={{ width: `${progress}%` }}
          />
        </div>

        {/* Document Image or Placeholder */}
        {progress === 100 && document ? (
          <div className="mt-2 h-56 object-fill">
            <DocumentImage
              open={open}
              document={document}
              onChange={(doc) => {
                setDocuments((prevDocuments) =>
                  prevDocuments.map((d) => (d.id === doc.id ? doc : d))
                );
              }}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-40 bg-gray-100 rounded">
            <span className="text-gray-500">Laster opp...</span>
          </div>
        )}

        {/* Document Form */}
        {progress === 100 && document && (
          <div className="flex flex-col gap-2 mt-4">
            <PMField
              key={document.id}
              name="title"
              type="text"
              label="Tittel"
              value={document.title || ""}
              onChange={(e) => onItemRowChange(e, document.id)}
            />
            {module === "project" && moduleParam && (
              <ProjectFolderSelector
                projectId={module === "project" ? moduleParam : null}
                selected={document?.folder?.id ? document?.folder : null}
                onChange={(e) => onItemRowChange(e, document.id)}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <UploaderV3
        required={props.required}
        onLoading={handleUploadStart}
        onFilesUploaded={handleOnFilesUploaded}
        onProgressChange={handleProgressChange}
        onFileSelection={handleFileSelection} // Pass the handler here
        module={module}
        module_param={moduleParam}
        size=""
        version={version}
        multiple={multiple}
        allowedFileTypes={allowedFileTypes}
        iconOnly={props.iconOnly}
      />
      <PMDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Nye dokumenter"
      >
        <ResourceLoading
          isVisible={!allUploadsComplete}
          label="Laster opp bilder...."
        />

        {/* Animated Checkbox with "Upload Complete" */}
        {allUploadsComplete ? (
          <div className="flex items-center justify-center gap-2 mt-6 animate-bounce">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-green-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span className="text-green-500 font-medium">Upload complete</span>
          </div>
        ) : (
          <div className="mt-6 text-center italic text-gray-150">
            <p>
              Laster opp {totalFiles} dokumenter. Vennligst vent til alle
              dokumentene er lastet opp.
            </p>
          </div>
        )}

        <div
          className="mt-10 max-h-[500px] overflow-y-auto"
          ref={containerRef} // Scrollable container
        >
          {/* Render progress bars and documents */}
          {Object.keys(uploadProgress).map((id) =>
            renderDocumentWithProgress(id)
          )}
        </div>

        <div className="flex justify-between mt-4">
          <button
            type="button"
            className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Steng
          </button>

          <button
            disabled={!allUploadsComplete}
            type="button"
            className="disabled:bg-gray-500 disabled:cursor-progress inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            onClick={() => {
              updateDocumentAsync();
            }}
          >
            Lagre
          </button>
        </div>
      </PMDialog>
    </>
  );
};

DocumentUploadSectionV3.defaultProps = {
  version: 3,
  multiple: true,
  iconOnly: false,
};
