import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ResourceLoading } from "./ResourceLoading";
import { TWclassNames } from "./Div";
import { Link, useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const PMDialog = (props) => {
  const {
    isLoading,
    title,
    children,
    onClose,
    open,
    size,
    headerActions,
    closeOnOutsideClick,
  } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const sizeClasses = {
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-lg",
    xl: "max-w-xl",
    "2xl": "max-w-2xl",
    "3xl": "max-w-3xl",
    "4xl": "max-w-4xl",
    "5xl": "max-w-5xl",
    "6xl": "max-w-6xl",
    "7xl": "max-w-7xl",
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeOnOutsideClick ? onClose : () => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0 py-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={TWclassNames(
                    "lg:" + sizeClasses[size],
                    sizeClasses[size],
                    "w-full transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all",
                    "mb-[10vh] mt-[10vh]"
                  )}
                >
                  <div className="mt-3 sm:mt-5 mb-10">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="flex justify-between items-center text-lg font-medium leading-6 text-gray-900"
                      >
                        <div className="">{t(title)}</div>
                        <div className=" relative right-0 text-right">
                          {/* Close button  */}
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={onClose}
                          >
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                          {headerActions
                            ?.filter((action) => !action.hidden)
                            .map((action) => {
                              if (action.path) {
                                return (
                                  <Link
                                    key={action.title}
                                    to={action.path}
                                    type="button"
                                    className="mr-2 inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    <action.icon
                                      className="-ml-0.5 mr-2 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                    {action.label}
                                  </Link>
                                );
                              } else {
                                return (
                                  <button
                                    key={action.title}
                                    onClick={action.action}
                                    type="button"
                                    className="mr-2 inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    <action.icon
                                      className="-ml-0.5 mr-2 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                    {action.label}
                                  </button>
                                );
                              }
                            })}
                        </div>
                      </Dialog.Title>
                    )}
                    <div className="mt-5">
                      {isLoading ? <ResourceLoading /> : children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

PMDialog.defaultProps = {
  open: false,
  onClose: () => {},
  children: "Content missing",
  // title: "Title not set",
  isLoading: false,
  size: "2xl",
};

PMDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl"]),
};
