import toast from "react-hot-toast";
import { useState } from "react";
import CustomerDialog from "../../customers/dialog";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import ServiceReportEditModal from "./service-report-edit-modal";
import CustomerSystemDialog from "../../customers/systems/system-dialog";
import { useDispatch } from "react-redux";
import { updateReportAsync } from "../../../../slices/service/reports";
import { mapErrors } from "../../../Utils";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ProjectTag } from "../../../utils/moduleTags/projectTag";
import { CustomerTag } from "../../../utils/moduleTags/customerTag";
import { CustomerSystemTag } from "../../../utils/moduleTags/customerSystemTag";

export const ReportInfo = (props) => {
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [customerSystemDialogOpen, setCustomerSystemDialogOpen] =
    useState(false);
  const { report, isLoading } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (isLoading) return <ResourceLoading />;

  return (
    <>
      {/* Description list*/}
      <section
        className="bg-white shadow sm:rounded-lg max-h-full"
        aria-labelledby="applicant-information-title"
      >
        <div className="">
          <div className="flex items-center px-4 py-5 sm:px-6 w-full">
            <div className="block">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Detaljer
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Informasjon om rapporten
              </p>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3">
              <div className="sm:col-span-1">
                <CustomerTag customer={report?.customer} />
              </div>
              <div className="sm:col-span-1">
                <ProjectTag project={report?.project} folder={report?.folder} />
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Type</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.report_type?.title}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <CustomerDialog
        open={customerDialogOpen}
        onClose={() => setCustomerDialogOpen(false)}
        readOnly={true}
        // selected={{ report.customer }}
        // Only for testing
        selected={report?.customer}
        onRefresh={() => props.onRefresh()}
      />
    </>
  );
};
