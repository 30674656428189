import {
  ArrowPathRoundedSquareIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import { toast } from "react-hot-toast";
import { CompanyIntegrationDialog } from "../../../components/dashboard/company/integrations/dialog";
import { useState } from "react";
import {
  deleteTripletexSubscriptions,
  fetchCompany,
  syncModule,
  updateCompany,
} from "../../../api/company";
import { useEffect } from "react";
import SimplePrompt from "../../../components/utils/simplePrompt";
import { mapErrors } from "../../../components/Utils";
import { useTranslation } from "react-i18next";
import SyncDialog from "../../../components/dashboard/company/integrations/sync-dialog";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { PMField } from "../../../components/utils/PMField";
import PMListboxFormik from "../../../components/utils/PMListboxFormik";
import PMSwitch from "../../../components/utils/PMSwitch";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { FormikSaveBar } from "../../../components/utils/FormikSaveBar";
import { CustomerSelector } from "../../../components/utils/moduleSelectors/customerSelector";
import { ProjectSelector } from "../../../components/utils/moduleSelectors/projectSelector";
import { updateCompanyAsync } from "../../../slices/company";
import { PMImagePicker } from "../../../components/utils/PMImagePicker";
import { PaymentTypeSelector } from "../../../components/utils/moduleSelectors/paymentTypeSelector";
import { ProductSelector } from "../../../components/utils/Selector/productSelector";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";

export const CompanySettings = () => {
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getCompany = () => {
    setIsLoading(true);
    fetchCompany()
      .then((res) => {
        // Set companyState
        setIsLoading(false);
        setCompany(res.data);
      })
      .catch((error) => {
        // Set companyState (errors, error and isLoading)
        setError(true);
        setErrors(error.data.errors);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  const onRefresh = (data) => {
    // Update companyState
    setCompany(data);
  };

  const headerActions = [
    // {
    //   component: null,
    //   label: "Sync Tripletex with promang",
    //   description: "",
    //   path: null,
    //   action: () => setSyncDialogOpen(true),
    //   icon: ArrowPathRoundedSquareIcon,
    //   textColor: "text-teal-700",
    //   bgColor: "bg-white",
    //   hidden: false,
    //   menu: true,
    // },
  ];

  function Content() {
    return (
      <>
        <div className="col-span-3">
          <Formik
            initialValues={{
              name: company?.name,
              vat: company?.vat,
              mobile: company?.mobile,
              email: company?.email,
              web: company?.web,
              phone: company?.phone,

              sales_conditions_url: company?.sales_conditions_url,
              customer_review_url: company?.customer_review_url,

              default_webshop_order_project_id:
                company?.default_webshop_order_project_id,

              postal_address: {
                address: company?.postal_address?.address,
                address2: company?.postal_address?.address2,
                postal: company?.postal_address?.postal,
                place: company?.postal_address?.place,
              },
              physical_address: {
                address: company?.physical_address?.address,
                address2: company?.physical_address?.address2,
                postal: company?.physical_address?.postal,
                place: company?.physical_address?.place,
              },

              document_logo_id: company?.logo?.id,

              submit: null,
            }}
            validationSchema={Yup.object({
              name: Yup.string().max(255).required(t("customer.name.required")),
              description: Yup.string(),
            })}
            onSubmit={(values, helpers) => {
              try {
                toast.promise(dispatch(updateCompanyAsync(values)), {
                  loading: t("Saving...."),
                  success: (data) => {
                    if (data.status === 500) throw new Error("Server error");
                    onRefresh(data?.payload?.data?.data);
                    return t("Data was saved!");
                  },
                  error: t("Something went wrong. Could not save data!"),
                });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                toast.error("Nope");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form className="grid-span-1 sm:grid-span-2 w-full">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-1 sm:col-span-2">
                    <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                        {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                        <div className="grid sm:grid-cols-8 gap-6">
                          <div className="sm:col-span-2">
                            {/* <PMSwitch label="Active" name="active" {...formik} /> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            {t("Company Details")}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500"></p>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <PMField
                              name="name"
                              type="text"
                              label="Company Name"
                              {...formik}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <PMField
                              name="vat"
                              type="text"
                              label="Vat"
                              {...formik}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            {t("Contact information")}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500"></p>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <PMField
                              name="mobile"
                              type="text"
                              label="Mobile"
                              {...formik}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <PMField
                              name="phone"
                              type="text"
                              label="Phone"
                              {...formik}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <PMField
                              name="email"
                              type="text"
                              label="Email"
                              {...formik}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            {t("Company Links")}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500"></p>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <PMField
                              name="sales_conditions_url"
                              type="text"
                              label="Sales Conditions URL"
                              {...formik}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <PMField
                              name="customer_review_url"
                              type="text"
                              label="Customer Review URL"
                              {...formik}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                      <div className="grid sm:grid-cols-2 gap-6">
                        <div className="grid grid-cols-3 sm:grid-cols-3 bg-white sm:p-6 gap-6">
                          <div className="col-span-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              {t("Postal Address")}
                            </h3>
                            <p className="mt-1 text-sm text-gray-500"></p>
                          </div>
                          <div className="col-span-3 sm:col-span-3">
                            <PMField
                              name="postal_address.address"
                              type="text"
                              label="Address"
                              {...formik}
                            />
                          </div>
                          <div className="col-span-3 sm:col-span-3">
                            <PMField
                              name="postal_address.address2"
                              type="text"
                              label="Address 2"
                              {...formik}
                            />
                          </div>
                          <div className="col-span-1 sm:col-span-1">
                            <PMField
                              name="postal_address.postal"
                              type="text"
                              label="Postal"
                              placeName="postal_address.place"
                              {...formik}
                            />
                          </div>
                          <div className="col-span-2 sm:col-span-2">
                            <PMField
                              name="postal_address.place"
                              type="text"
                              label="Place"
                              {...formik}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-3 sm:grid-cols-3 bg-white sm:p-6 gap-6">
                          <div className="col-span-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              {t("Physical Address")}
                            </h3>
                            <p className="mt-1 text-sm text-gray-500"></p>
                          </div>
                          <div className="col-span-3 sm:col-span-3">
                            <PMField
                              name="physical_address.address"
                              type="text"
                              label="Address"
                              {...formik}
                            />
                          </div>
                          <div className="col-span-3 sm:col-span-3">
                            <PMField
                              name="physical_address.address2"
                              type="text"
                              label="Address 2"
                              {...formik}
                            />
                          </div>
                          <div className="col-span-1 sm:col-span-1">
                            <PMField
                              name="physical_address.postal"
                              type="text"
                              label="Postal"
                              {...formik}
                            />
                          </div>
                          <div className="col-span-2 sm:col-span-2">
                            <PMField
                              name="physical_address.place"
                              type="text"
                              label="Place"
                              {...formik}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="col-span-1 sm:col-span-2">
                      <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                          {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                          <div className="grid sm:grid-cols-8 gap-6">
                            <div className="sm:col-span-2">
                              {/* <PMSwitch label="Active" name="active" {...formik} /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* logo upload start */}
                      <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <PMImagePicker
                                label="Logo"
                                selected={company?.logo}
                                name="document_logo_id"
                                {...formik}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* logo upload end */}
                      {/* default_webshop_order_project_id start */}
                      <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <ProjectSelector
                                label="Default Webshop Order Project"
                                name="default_webshop_order_project_id"
                                selected={
                                  company?.default_webshop_order_project?.id
                                    ? company?.default_webshop_order_project
                                    : null
                                }
                                {...formik}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* default_webshop_order_project_id end */}
                      {/* default_webshop_order_project_id start */}
                      {company?.stripe_webhook_secret && (
                        <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                              <div className="col-span-6 sm:col-span-6">
                                <PaymentTypeSelector
                                  label="Stripe Payment Type"
                                  name="stripe_payment_type_id"
                                  selected={
                                    company?.stripe_payment_type?.id
                                      ? company?.stripe_payment_type
                                      : null
                                  }
                                  {...formik}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* default_webshop_order_project_id end */}

                      {/* car_allowance_product start */}
                      {company?.car_allowance_product && (
                        <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                              <div className="col-span-6 sm:col-span-6">
                                <ProductSelector
                                  label="Car Allowance Product"
                                  name="car_allowance_product_id"
                                  selected={
                                    company?.car_allowance_product?.id
                                      ? company?.car_allowance_product
                                      : null
                                  }
                                  {...formik}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* car_allowance_product end */}

                      {/* default_service_hour_product start */}
                      {company?.default_service_hour_product && (
                        <div className="mt-5 shadow sm:overflow-hidden sm:rounded-md">
                          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                              <div className="col-span-6 sm:col-span-6">
                                <ProductSelector
                                  label="Default Service Hour Product"
                                  name="default_service_hour_product_id"
                                  selected={
                                    company?.default_service_hour_product?.id
                                      ? company?.default_service_hour_product
                                      : null
                                  }
                                  {...formik}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* default_service_hour_product end */}
                    </div>
                  </div>
                </div>

                {/* <PromptIfDirty {...formik}/> */}
                <FormikSaveBar {...formik} />
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Settings"}
        meta={[]}
        // mainButton={{
        //   label: "Edit",
        //   action: () => setReportDialogOpen(true),
        //   path: null,
        //   color: "",
        //   icon: PencilIcon,
        // }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <Content />
        </div>
      </DashboardHeader>
    </>
  );
};
