import React, { useState, useEffect, useRef } from "react";
import axios from "../../../api/axios"; // Backend API
import Axios from "axios"; // Separate Axios for S3

export const UploaderV4 = ({
  onUploadStart,
  filesToUpload,
  onUploadComplete,
  onProgressChange,
  onFileUploadComplete,
  onReset,
}) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const uploadFunctionRef = useRef(null);
  const completedUploads = useRef(new Set()); // ✅ Track completed uploads

  useEffect(() => {
    uploadFunctionRef.current = startUpload;
  }, [filesToUpload]);

  useEffect(() => {
    if (onUploadStart && uploadFunctionRef.current) {
      // ✅ Ensure uploadFunctionRef is set
      console.log("onUploadStart is setting a valid upload function ✅");
      onUploadStart(() => {
        console.log("Calling stored upload function");
        return uploadFunctionRef.current();
      });
    }
  }, [uploadFunctionRef.current]); // ✅ Only run when upload function is available

  const startUpload = async () => {
    if (!filesToUpload || filesToUpload.length === 0) return;
    console.log("Starting upload...");

    const uploadedFiles = [];

    for (const { file, title, module, moduleParam } of filesToUpload) {
      try {
        const response = await axios.get("/uploads/presigned_url", {
          params: { filename: file.name },
        });

        const { url, document_id } = response.data;
        if (!url || !document_id) throw new Error("Invalid presigned URL");

        await Axios.put(url, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadProgress((prev) => ({
              ...prev,
              [document_id]: percentCompleted,
            }));

            onProgressChange({
              ...uploadProgress,
              [document_id]: percentCompleted,
            });
          },
        });

        // ✅ FLYTT onFileUploadComplete HER (etter backend bekreftelse)
        const fileResponse = await axios.patch("/uploads/complete_upload", {
          id: document_id,
          filename: file.name,
          title,
          module,
          module_param: moduleParam,
        });

        uploadedFiles.push(fileResponse.data.document);

        // 🚀 Øk telleren kun når backend bekrefter at filen er fullført
        if (onFileUploadComplete) {
          onFileUploadComplete();
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }

    onUploadComplete(uploadedFiles);
  };

  // ✅ Function to reset upload progress
  const resetUploadProgress = () => {
    console.log("Resetting upload progress... ✅");
    setUploadProgress({});
    completedUploads.current.clear();
  };

  // ✅ Ensure `onReset` gets the reset function when component mounts
  useEffect(() => {
    if (onReset) {
      console.log("Providing reset function to parent ✅");
      onReset(() => resetUploadProgress);
    }
  }, [onReset]);

  return null;
};
