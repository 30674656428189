import { useState } from "react";
import CustomerDialog from "../../dashboard/customers/dialog";
import { toast } from "react-hot-toast";
import { ModuleTag } from "./moduleTag";
import CustomerSystemDialog from "../../dashboard/customers/systems/system-dialog";

export const CustomerSystemTag = ({
  selected,
  customer,
  onRefresh,
  onAttachSystem,
  module,
  moduleParam,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const labelAction = {
    // path: "/dashboard/customers/" + customer?.id,
    action: true,
  };

  if (!customer?.id) return toast.error("Customer id is not defined");

  return (
    <>
      <ModuleTag
        label="Anlegg"
        labelAction={labelAction}
        onAction={() => {
          setDialogOpen(true);
        }}
      >
        {selected?.displayTitle}
      </ModuleTag>

      <CustomerSystemDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        readOnly={true}
        selectedSystem={selected?.id}
        customer={customer}
        onAttachSystem={onAttachSystem}
        module={module}
        moduleParam={moduleParam}
      />
    </>
  );
};

CustomerSystemTag.defaultProps = {
  onAttachSystem: () => toast.error("onAttachSystem is not defined"),
  customer: {},
  selected: {},
  onRefresh: () => {},
};
