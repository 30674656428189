import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import {
  createReportTypeAsync,
  getReportTypeAsync,
  updateReportTypeAsync,
} from "../../../../slices/service/report-types";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { fetchReportType } from "../../../../api/service/settings";
import { ActivitySelector } from "../../../utils/moduleSelectors/activitySelector";
import { QuillEditor } from "../../../utils/quill-editor";

export default function ServiceReportTypeModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { open, onClose, itemId } = props;

  const [itemState, setItemState] = useState({});

  const { report_type, isLoading } = itemState;

  const getItem = (itemId) => {
    setItemState({ ...itemState, isLoading: true });

    fetchReportType(itemId)
      .then((data) => {
        setItemState({ ...itemState, report_type: data, isLoading: false });
      })
      .catch((err) => {
        setItemState({ ...itemState, error: err, isLoading: false });
      });
  };

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
    } else {
      setItemState({ ...itemState, report_type: null });
    }
  }, [open]);

  const createOrUpdateDispatch = (data) => {
    if (report_type?.id) {
      const res = dispatch(updateReportTypeAsync(data));
      return res;
    } else {
      const res = dispatch(createReportTypeAsync(data));
      return res;
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isLoading ? (
                  <ResourceLoading />
                ) : (
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {itemId
                        ? t("service.report_types.edit")
                        : t("service.report_types.create")}
                    </Dialog.Title>

                    {isLoading ? (
                      "Loading..."
                    ) : (
                      <Formik
                        initialValues={{
                          id: report_type?.id,
                          title: report_type?.title,
                          description: report_type?.description,
                          active: report_type?.active,
                          system: report_type?.system,
                          agreement: report_type?.agreement,
                          simple_report: report_type?.simple_report,
                          conditions: report_type?.conditions,
                          items: report_type?.items,
                          checklist: report_type?.checklist,
                          hours: report_type?.hours,
                          variables: report_type?.variables,
                          submit: null,
                        }}
                        validationSchema={Yup.object({
                          title: Yup.string()
                            .matches(
                              /^[a-zA-Z0-9\s]+$/,
                              t("Title cannot include the '&' symbol")
                            )
                            .max(255)
                            .required(t("title.required")),
                          description: Yup.string().max(500),
                        })}
                        onSubmit={(values, helpers) => {
                          try {
                            toast.promise(createOrUpdateDispatch(values), {
                              loading: t("Saving...."),
                              success: (data) => {
                                if (data.status === 500)
                                  throw new Error("Server error");
                                if (data.payload.errors)
                                  throw new Error(
                                    mapErrors(data.payload.errors)
                                  );
                                onClose(true);
                                return t("Data was saved!");
                              },
                              error: (error) => {
                                return t(
                                  "Something went wrong. Could not save data!"
                                );
                              },
                            });
                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10">
                              <div className="col-span-6 sm:col-span-3">
                                {report_type?.id}
                                <PMField
                                  name="title"
                                  type="text"
                                  label="Title"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3"></div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMField
                                  name="description"
                                  type="text"
                                  label="Description"
                                  multiline
                                  rows={2}
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <QuillEditor
                                  name="conditions"
                                  type="text"
                                  label="Betingelser"
                                  subtitle="Sendes også med e-post ved bekreftelse av avtale"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="active"
                                  label="Active"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="system"
                                  label="System"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="simple_report"
                                  label="Simple Report"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="agreement"
                                  label="Agreement"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="items"
                                  label="Items"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="checklist"
                                  label="Checklist"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="hours"
                                  label="Hours"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6">
                                <PMSwitch
                                  name="variables"
                                  label="variables"
                                  {...formik}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3 lg:col-span-2"></div>
                            </div>

                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>

                              <button
                                type="button"
                                className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={formik.submitForm}
                              >
                                {report_type?.id ? t("Save") : t("Create")}{" "}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
