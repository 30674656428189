import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { useDispatch, useSelector } from "react-redux";
import { ResourceError } from "../../../../components/utils/ResourceError";
import {
  ArrowPathRoundedSquareIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import {
  axiosWithToast,
  mapErrors,
  updateUrlWithException,
} from "../../../../components/Utils";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import { HistoriesModalButton } from "../../../../components/dashboard/histories/modal-button";
import {
  deleteServiceAgreement,
  fetchServiceAgreement,
  reactivateServiceAgreement,
} from "../../../../api/service/agreement";
import { ServiceAgreementInfo } from "../../../../components/dashboard/service/agreements/agreement-info";
import { ReportsStackList } from "../../../../components/dashboard/service/reports/reports-stack-list";
import { ServiceAgreementForm } from "../../../../components/dashboard/service/agreements/agreement-form";
import { Notes } from "../../../../components/dashboard/notes/notes";
import { ServiceAgreementNewReportSection } from "../../../../components/dashboard/service/agreements/new-report-section";
import { ServiceAgreementChangeDateSection } from "../../../../components/dashboard/service/agreements/change-date-section";
import { ServiceAgreementStatus } from "../../../../components/dashboard/service/agreements/utils";
import SimplePrompt from "../../../../components/utils/simplePrompt";
import { ServiceAgreementOrderlines } from "../../../../components/dashboard/service/agreements/agreement-orderlines";
import { OrdersStacklist } from "../../../../components/dashboard/orders/orders-stack-list";
import { PMTabs } from "../../../../components/utils/PMTabs";
import { ServiceAgreementDocuments } from "./documents";

export const ServiceAgreement = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    agreement: {},
  });

  const [searchParams, setSearchParams] = useSearchParams();

  // Deconstruct itemState (Store)
  const { isLoading, error, errors, agreement } = itemState;

  const item = agreement;

  const [selectedTab, setSelectedTab] = useState(
    parseInt(searchParams.get("tab")) || 0
  );

  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        tab: selectedTab,
      }
    );
  }, [selectedTab]);

  useEffect(() => {
    getAgreement();
  }, [dispatch]);

  const getAgreement = async () => {
    return fetchServiceAgreement(id)
      .then((res) => {
        if (res?.data?.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            agreement: res?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          agreement: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const handleRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      agreement: data,
    }));
  };

  const [cancelPrompt, setCancelPrompt] = useState(false);

  const cancelAgreement = async () => {
    const payload = {
      id: item?.id,
    };
    axiosWithToast(deleteServiceAgreement(payload), (data) =>
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        agreement: data?.data,
      }))
    );
  };

  const [reactivatePrompt, setReactivatePrompt] = useState(false);

  const reactivateAgreement = async () => {
    const payload = {
      id: item?.id,
    };

    axiosWithToast(reactivateServiceAgreement(payload), (data) =>
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        agreement: data?.data,
      }))
    );
  };

  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const tabs = [
    {
      name: "Detaljer",
      component: <ServiceAgreementInfo item={item} isLoading={isLoading} />,
    },
    {
      name: "Rediger",
      component: (
        <ServiceAgreementForm
          item={item}
          onRefresh={(data) => {
            handleRefresh(data);
          }}
        />
      ),
    },
    {
      name: "Documents",
      component: <ServiceAgreementDocuments moduleParam={item?.id} />,
    },
    {
      name: "Ordrelinjer",
      component: <ServiceAgreementOrderlines item={item} />,
    },
    {
      name: "Oppdrag",
      component: (
        <ReportsStackList
          module_param={item.id}
          module="agreement"
          filters={[
            {
              name: "agreement",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Ordrer",
      component: (
        <OrdersStacklist
          module_param={item.id}
          module="agreement"
          filters={[
            {
              name: "agreement",
              value: item.id,
            },
          ]}
        />
      ),
    },
  ];

  const headerActions = [
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"service_agreement"} module_param={item?.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <HistoriesModalButton
          module={"service_agreement"}
          module_param={item?.id}
        />
      ),
      label: "History",
      description: "",
      path: null,
      action: () => toast.success("Hej Robin"),
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
    {
      label: "Kanseler",
      description: "",
      path: null,
      action: () => setCancelPrompt(true),
      icon: ExclamationTriangleIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: item?.status === "cancelled",
      menu: true,
    },
    {
      label: "Reaktiver",
      description: "",
      path: null,
      action: () => setReactivatePrompt(true),
      icon: ExclamationTriangleIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: item?.status !== "cancelled",
      menu: true,
    },
  ];

  const headerMeta = [
    {
      component: <ServiceAgreementStatus serviceAgreement={agreement} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },
  ];

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="">
          <ResourceLoading />
        </div>
      );
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    if (!item?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <DashboardHeader
          subtitle=""
          actions={headerActions}
          title={agreement?.displayTitle}
          meta={headerMeta}
          // mainButton={{
          //   label: "New",
          //   action: () => setCaseDialogOpen(true),
          //   path: null,
          //   color: "",
          //   icon: PlusIcon,
          // }}
        >
          <div className="mx-auto mt-8 grid max-w-7xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
            <div className="space-y-6 lg:col-span-4 lg:col-start-1 sm:col-span-4">
              <PMTabs
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              {tabs[selectedTab].component}
            </div>
            <div className="space-y-6 lg:col-span-4 lg:col-start-5 sm:col-span-4">
              <ServiceAgreementNewReportSection item={agreement} />
              <ServiceAgreementChangeDateSection
                item={agreement}
                onRefresh={(data) => handleRefresh(data)}
              />
            </div>
          </div>
        </DashboardHeader>

        <SimplePrompt
          title="Kanseler serviceavtale"
          description="Er du sikker på at du vill kanselere denne serviceavtalen?"
          open={cancelPrompt}
          onClose={() => setCancelPrompt(false)}
          onAccept={() => cancelAgreement()}
        />

        <SimplePrompt
          title="Reaktivere serviceavtale"
          description="Er du sikker på at du vill aktivere denne serviceavtalen igjen?"
          open={reactivatePrompt}
          onClose={() => setReactivatePrompt(false)}
          onAccept={() => reactivateAgreement()}
        />
      </>
    );
  };

  return <>{renderContent()}</>;
};
