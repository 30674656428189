import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { useDispatch, useSelector } from "react-redux";
import { ResourceError } from "../../../components/utils/ResourceError";
import { Grid } from "@mui/material";

import { ProjectInfo } from "../../../components/dashboard/projects/project-info";
import PMLinkCard from "../../../components/utils/PMLinkCard";
import {
  CameraIcon,
  ArrowPathRoundedSquareIcon,
  ExclamationCircleIcon,
  FolderIcon,
  PencilIcon,
  PaperClipIcon,
  WrenchScrewdriverIcon,
  CheckCircleIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import {
  ItemStatus,
  mapErrors,
  updateUrlWithException,
} from "../../../components/Utils";
import { fetchProject } from "../../../api/project";
import { Notes } from "../../../components/dashboard/notes/notes";
import { HistoriesModalButton } from "../../../components/dashboard/histories/modal-button";
import DeviationNewDialog from "../../../components/dashboard/quality/deviations/deviationNewDialog";
import { ReportsStackList } from "../../../components/dashboard/service/reports/reports-stack-list";
import { ServiceAgreementsStackList } from "../../../components/dashboard/service/agreements/agreements-stack-list";
import { TWclassNames } from "../../../components/utils/Div";
import { FoldersStackList } from "../../../components/dashboard/projects/folders/stack-list";
import { DocumentUploadSection } from "../../../components/dashboard/Media/DocumentUploadSection";
import { DeviationStacklist } from "../../../components/dashboard/quality/deviations/deviationStacklist";
import { ChecklistStacklist } from "../../../components/dashboard/quality/checklists/checklistStacklist";
import { FieldsSection } from "../../../components/dashboard/fields/section";

export const Project = (props) => {
  const { id } = useParams();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    project: {},
  });

  const [deviationDialogOpen, setDeviationDialogOpen] = useState(false);

  const rightSideActions = [
    {
      label: "Nytt Avvik",
      description: "Opprett et nytt avvik for dette prosjektet",
      path: "",
      action: () => setDeviationDialogOpen(true),
      icon: ExclamationCircleIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
  ];

  const getProject = async () => {
    return fetchProject(id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            project: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          project: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    getProject();
  }, []);

  const handleOnRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        project: data,
      }));
    } else {
      getProject();
    }
  };

  const { isLoading, error, errors, project } = itemState;

  const item = project;

  const headerMeta = [
    {
      component: <ItemStatus item={project} />,
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: null,
      textColor: "text-teal-700",
      hidden: false,
    },
  ];

  const headerActions = [
    {
      component: (
        <HistoriesModalButton module={"project"} module_param={project?.id} />
      ),
      label: "History",
      description: "",
      icon: ArrowPathRoundedSquareIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
    {
      component: (
        <div className="px-2 mt-2">
          <Notes module={"project"} module_param={project.id} />
        </div>
      ),
      label: "Status",
      description: "",
      path: null,
      action: null,
      icon: CameraIcon,
      textColor: "text-teal-700",
      bgColor: "bg-red-200",
      hidden: false,
      menu: false,
    },
  ];

  const tabs = [
    {
      name: "Detaljer",
      component: <ProjectInfo item={item} onRefresh={handleOnRefresh} />,
      icon: PencilIcon,
    },

    {
      name: "Fields",
      component: <FieldsSection module={"project"} moduleParam={item?.id} />,
      icon: TableCellsIcon,
    },

    {
      name: "Reports",
      icon: WrenchScrewdriverIcon,
      component: (
        <ReportsStackList
          module_param={item.id}
          module="project"
          fields="id, displayTitle, report_number, status, executing_employee, delivery_address, customer, report_type, appointment, description"
          per_page="10"
          filters={[
            {
              name: "project",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Folders",
      icon: FolderIcon,
      component: (
        <FoldersStackList
          project={item}
          // filters={[
          //   {
          //     name: "project",
          //     value: item.id,
          //   },
          // ]}
        />
      ),
    },
    {
      name: "Agreements",
      icon: PaperClipIcon,
      component: (
        <ServiceAgreementsStackList
          module_param={item.id}
          module="project"
          filters={[
            {
              name: "project",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Sjekklister",
      icon: CheckCircleIcon,
      component: (
        <ChecklistStacklist
          module_param={item.id}
          module="project"
          filters={[
            {
              name: "project",
              value: item.id,
            },
          ]}
        />
      ),
    },
    {
      name: "Avvik",
      icon: PaperClipIcon,
      component: (
        <DeviationStacklist
          module_param={item.id}
          module="project"
          filters={[
            {
              name: "project",
              value: item.id,
            },
          ]}
        />
      ),
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(
    parseInt(searchParams.get("tab")) || 0
  );

  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        tab: selectedTab,
      }
    );
  }, [selectedTab]);

  function projectTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => setSelectedTab(e.target.value)}
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[selectedTab].name}
          >
            {tabs.map((tab, tabIdx) => (
              <option key={tab.name} value={tabIdx}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                onClick={() => setSelectedTab(tabIdx)}
                className={TWclassNames(
                  tabIdx === selectedTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tabIdx === selectedTab ? "page" : undefined}
              >
                <div className="flex">
                  {tab?.icon && <tab.icon className="h-5 w-5 mr-2" />}
                  <span className="">{tab.name}</span>
                </div>
                <span
                  aria-hidden="true"
                  className={TWclassNames(
                    tabIdx === selectedTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return <ResourceLoading />;
    }
    if (error && errors) {
      return <ResourceError errors={errors} />;
    }

    return (
      <>
        <DashboardHeader
          subtitle=""
          actions={headerActions}
          title={project?.displayTitle}
          meta={headerMeta}
          //   mainButton={{
          //     label: "New",
          //     action: () => setCaseDialogOpen(true),
          //     path: null,
          //     color: "",
          //     icon: PlusIcon,
          //   }}
        >
          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
              <Grid container spacing={3}>
                <Grid
                  container
                  item
                  lg={8}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                >
                  <Grid item xs={12}>
                    {projectTabs()}
                    <div className="py-5">{tabs[selectedTab].component}</div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  lg={4}
                  spacing={3}
                  sx={{ height: "fit-content", order: { md: 2, xs: 1 } }}
                >
                  <Grid item xs={12}>
                    <DocumentUploadSection
                      module="project"
                      moduleParam={item?.id}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PMLinkCard actions={rightSideActions} />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </DashboardHeader>

        <DeviationNewDialog
          open={deviationDialogOpen}
          onClose={() => setDeviationDialogOpen(false)}
          project={item}
          customer={item?.customer}
        />
      </>
    );
  };

  return <>{renderContent()}</>;
};
