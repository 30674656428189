import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateServiceAgreementAsync } from "../../../../slices/service/agreements";
import PMSwitch from "../../../utils/PMSwitch";
import { PMField } from "../../../utils/PMField";
import { FormikSaveBar } from "../../../utils/FormikSaveBar";
import { dispatchWithToast } from "../../../Utils";
import { CustomerSelector } from "../../../utils/moduleSelectors/customerSelector";
import { ProjectSelector } from "../../../utils/moduleSelectors/projectSelector";
import { SystemSelector } from "../../../utils/moduleSelectors/systemSelector";
import { ContactsSelector } from "../../../utils/moduleSelectors/contactSelector";
import { ServiceAgreementTypeSelector } from "../../../utils/moduleSelectors/serviceAgreementTypeSelector";
import { DeliveryAddressSelector } from "../../../utils/moduleSelectors/deliveryAddressSelector";
import { QuillEditor } from "../../../utils/quill-editor";
import { ResourceLoading } from "../../../utils/ResourceLoading";

export const ServiceAgreementForm = (props) => {
  const { t } = useTranslation();
  const { item, onRefresh, isLoading } = props;
  const dispatch = useDispatch();

  if (isLoading) {
    return <ResourceLoading />;
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          id: item?.id,
          order_date: item?.order_date || new Date().toISOString().slice(0, 10),
          agreement_number: item?.agreement_number,
          customer_id: item?.customer?.id,
          invoice_customer_id: item?.invoice_customer?.id,
          project_id: item?.project?.id,
          agreement_type_id: item?.type?.id,
          interval_id: item?.interval?.id,
          invoice_customer: item?.invoice_customer,
          agreement_start_date:
            item?.agreement_start_date || new Date().toISOString().slice(0, 10),
          system_id: item?.system?.id,
          description: item?.description,
          agreement_text: item?.agreement_text,
          conditions: item?.conditions,
          invoice_to_project_customer: item?.invoice_to_project_customer,
        }}
        validationSchema={Yup.object({
          order_date: Yup.date().required(t("Order date is required")),
          customer_id: Yup.string().required(t("Order date is required"), () =>
            toast.error("Customer ID is missing")
          ),
          agreement_type_id: Yup.string().required(t("Type is required")),
          interval_id: Yup.string().required(t("Interval is required")),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              dispatch(updateServiceAgreementAsync(values)),
              (data) => onRefresh(data)
            );
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
          } catch (err) {
            toast.error("Something went wrong...");
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="bg-white shadow rounded-xl">
              <div className="px-5 py-5 grid grid-cols-6 gap-6">
                <div className="col-span-2 sm:col-span-2">
                  <PMSwitch
                    name="invoice_to_project_customer"
                    label="Invoice to Project Customer"
                    {...formik}
                  />
                </div>
                <div className="col-span-2 sm:col-span-2"></div>
                <div className="col-span-2 sm:col-span-2"></div>
                <div className="col-span-6 sm:col-span-3">
                  <PMField
                    name="order_date"
                    type="date"
                    label="Order Date"
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <PMField
                    name="agreement_start_date"
                    type="date"
                    label="Agreement Start Date"
                    {...formik}
                  />
                </div>

                {!props.customer?.id && (
                  <div className="col-span-6 sm:col-span-3">
                    <CustomerSelector
                      label="Kunde"
                      selected={item?.customer}
                      {...formik}
                    />
                  </div>
                )}

                <div className="col-span-6 sm:col-span-3">
                  <CustomerSelector
                    label="Fakturakunde"
                    name="invoice_customer_id"
                    selected={item?.invoice_customer}
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <ProjectSelector
                    label="Prosjekt"
                    selected={item?.project?.id ? item?.project : null}
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <SystemSelector
                    label="Anlegg"
                    module="CUSTOMER"
                    module_param={
                      item?.customer?.id
                        ? item?.customer?.id
                        : formik.values.customer_id
                    }
                    selected={item?.system}
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <ContactsSelector
                    selected={item?.contact}
                    module_param={item?.customer?.id}
                    name="contact_id"
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <ServiceAgreementTypeSelector
                    selected={item?.type}
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <DeliveryAddressSelector
                    selected={item?.delivery_address}
                    name="delivery_address_id"
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <PMField
                    name="description"
                    type="text"
                    label="Description"
                    multiline
                    {...formik}
                  />
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <span className="text-sm">Avtaletekst</span>
                  <QuillEditor name="agreement_text" rows={4} {...formik} />
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <span className="text-sm">Vilkår</span>
                  <QuillEditor name="conditions" rows={4} {...formik} />
                </div>
              </div>
            </div>

            <FormikSaveBar {...formik} />
          </Form>
        )}
      </Formik>
    </>
  );
};
