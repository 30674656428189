import React, { useEffect, useState } from "react";
import { TWclassNames } from "../../utils/Div";
import { CameraIcon } from "@heroicons/react/24/outline";
import axios from "../../../api/axios"; // Axios for backend
import toast from "react-hot-toast";
import Axios from "axios"; // Default Axios for S3 requests

export const UploaderV3 = (props) => {
  const {
    module_param,
    module,
    size,
    disabled,
    version,
    withoutModuleParam,
    required,
    onFilesUploaded,
    onProgressChange,
    onLoading,
    onFileSelection, // Added callback for file selection
    iconOnly,
  } = props;

  const allowedFileTypes =
    props.allowedFileTypes ||
    "image/png, image/jpeg, image/jpg, image/gif, image/svg, image/svg+xml, image/webp, application/pdf";

  const [progress, setProgress] = useState({}); // Track progress for each file
  const [uploading, setUploading] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]); // Track uploaded files

  // Reset the uploader state
  const resetUploader = () => {
    setProgress({});
    setFilesUploaded([]);
    if (onFilesUploaded) onFilesUploaded([]); // Notify parent of reset
    if (onProgressChange) onProgressChange({}); // Reset progress in parent
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    resetUploader();
    setUploading(true);
    if (onFileSelection) onFileSelection(files.length);
    if (onLoading) onLoading();

    try {
      for (const file of files) {
        // Initialize progress with document_id instead of file name
        setProgress((prev) => ({ ...prev }));

        const response = await axios.get("/uploads/presigned_url", {
          params: { filename: file.name, module, module_param },
        });

        const { url, document_id } = response.data;
        if (!url || !document_id)
          throw new Error("Missing presigned URL or document ID.");

        // Correct: Use document_id for tracking progress instead of file.name
        setProgress((prev) => ({ ...prev, [document_id]: 0 }));

        await Axios.put(url, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress((prev) => ({
              ...prev,
              [document_id]: percentCompleted,
            }));
            if (onProgressChange)
              onProgressChange({ [document_id]: percentCompleted });
          },
        });

        // Notify backend
        const fileResponse = await axios.patch("/uploads/complete_upload", {
          id: document_id,
          filename: file.name,
        });
        const uploadedDocument = fileResponse.data.document;

        // Correct: Prevent duplicates by checking existing IDs
        setFilesUploaded((prev) =>
          prev.some((doc) => doc.id === uploadedDocument.id)
            ? prev
            : [...prev, uploadedDocument]
        );
        setProgress((prev) => ({ ...prev, [document_id]: 100 }));

        console.log(`File uploaded: ${file.name}`);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(`Upload failed: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  // Sync progress and uploaded files with parent components
  useEffect(() => {
    if (onFilesUploaded) {
      onFilesUploaded(filesUploaded);
    }

    if (onProgressChange) {
      onProgressChange(progress);
    }
  }, [filesUploaded, progress]);

  const random = Math.random().toString(36).substring(2, 15);

  if (!module && !module_param && !withoutModuleParam) {
    return <div>Module, Module ID &/ or Company ID is missing.</div>;
  }

  return (
    <div
      className={TWclassNames(
        required ? "border-2 border-red-400" : "",
        "text-center bg-blue-400 hover:bg-blue-600 border-gray-300 px-5 py-5"
      )}
    >
      <label
        htmlFor={random + "-file-upload"}
        className={TWclassNames(
          disabled ? "cursor-not-allowed" : "bg-white",
          "relative cursor-pointer rounded-md font-medium text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-400 focus-within:ring-offset-2"
        )}
      >
        <div className="w-full h-full flex flex-row items-center justify-center">
          <CameraIcon className="h-5 w-6 mr-2 text-white" />
          {!iconOnly && (
            <div className="flex-row">
              <span>Last opp dokument/ bilde</span>
            </div>
          )}
        </div>
        <input
          accept={allowedFileTypes}
          disabled={uploading || disabled}
          multiple={props.multiple}
          id={random + "-file-upload"}
          name={random + "-file-upload"}
          type="file"
          className="sr-only"
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
};

UploaderV3.defaultProps = {
  key: null,
  size: "base",
  version: 1,
  onLoading: () => {},
  withoutModuleParam: false,
  iconOnly: false,
};
