import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CalendarIcon, MapPinIcon } from "@heroicons/react/20/solid";
import { getCasesAsync, loadMoreCasesAsync } from "../../../slices/case";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CaseDialog from "./case-dialog";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { ResourceError } from "../../utils/ResourceError";
import { toast } from "react-hot-toast";
import { InitialsTag } from "../../Utils";
import { Popup } from "../../utils/Div";
import { CasePriorityStatus, CaseStatus } from "./utils";

export const CasesStackList = (props) => {
  const dispatch = useDispatch();

  const itemState = useSelector((store) => {
    return store.cases;
  });

  const { isLoading, data, error, errors, totalCount } = itemState;

  const [caseDialogOpen, setCaseDialogOpen] = useState(false);

  const [controller, setController] = useState({
    filters: props.filters ? [...props.filters] : [],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    per_page: 50,
    view: "all",
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const caseId = searchParams.get("caseId");

  useEffect(() => {
    if (caseId && caseId !== "null" && caseId !== "undefined") {
      handleOpenCase({ id: caseId });
    }
  }, [caseId]);

  useEffect(() => {
    // await caseId and open
    if (!caseDialogOpen && !caseId) {
      if (controller.page === 1) {
        dispatch(getCasesAsync(controller));
      } else if (controller.page > 1) {
        dispatch(loadMoreCasesAsync(controller));
      }
    }
  }, [dispatch, controller]);

  const [selectedCase, setSelectedCase] = useState(null);

  function handleOpenCase(id) {
    setSelectedCase(id);
    setTimeout(() => {
      setCaseDialogOpen(true);
    }, 50);
  }

  const [isEditing, setIsEditing] = useState(false);

  function handleDialogClose() {
    if (!isEditing) {
      setCaseDialogOpen(false);
      setSelectedCase(null);
    } else {
      toast.error("Please save your changes first.");
    }
  }

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const hasMorePages = () => {
    return controller.page * controller.per_page < totalCount;
  };

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (!data) return <ResourceEmpty />;

  return (
    <>
      {data && (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {data?.map((caseItem) => (
              <li key={caseItem.id} onClick={() => handleOpenCase(caseItem)}>
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="flex flex-col truncate text-sm font-medium">
                        <span className="text-blue-400">
                          {caseItem?.case_number}
                        </span>
                        <span>{caseItem?.customer?.displayTitle}</span>
                      </p>
                      <div className="ml-2 flex flex-shrink-0">
                        <p className="flex flex-col gap-3">
                          <CaseStatus caseItem={caseItem} />
                          <CasePriorityStatus priority={caseItem?.priority} />
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          {caseItem?.note && (
                            <Popup content={caseItem?.note} textSize="md">
                              <InitialsTag
                                name={caseItem?.user_assigned?.name}
                              />
                            </Popup>
                          )}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {caseItem.case_type?.title}
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Date created{" "}
                          <time dateTime={caseItem.created_at}>
                            {caseItem.created_at}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {hasMorePages() && (
        <div className="bg-blue-200 hover:bg-blue-400 w-full py-2 shadow-md text-center hover:text-bold cursor-pointer rounded-md">
          <button
            type="button"
            onClick={() => handlePageChange(controller.page + 1)}
          >
            Load more...
          </button>
        </div>
      )}

      <CaseDialog
        module_param={props.module_param}
        module={props.module}
        open={caseDialogOpen}
        onClose={() => handleDialogClose()}
        selected={selectedCase}
        onRefresh={() => props.onRefresh()}
        onEditing={(boolean) => setIsEditing(boolean)}
      />
    </>
  );
};

CasesStackList.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
