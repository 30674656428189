import React, { useState, useRef, useEffect } from "react";
import { PMDialog } from "../../utils/PMDialog";
import { PMField } from "../../utils/PMField";
import { CameraIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { UploaderV4 } from "./Uploaderv4";
import { TWclassNames } from "../../utils/Div";

export const DocumentUploadSectionV4 = ({
  multiple,
  module,
  moduleParam,
  parentCallback,
  required,
  disabled,
  iconOnly,
}) => {
  const [open, setOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadedCount, setUploadedCount] = useState(0);
  const [startUpload, setStartUpload] = useState(null);
  const fileInputRef = useRef(null);
  const [resetUploader, setResetUploader] = useState(null); // ✅ Store reset function
  const handleFileSelection = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (!selectedFiles.length) return;

    setFilesToUpload((prev) => [
      ...prev,
      ...selectedFiles.map((file) => ({
        file,
        previewUrl: URL.createObjectURL(file),
        title: "",
        module,
        moduleParam,
      })),
    ]);
    setOpen(true);
  };

  const handleTitleChange = (index, title) => {
    setFilesToUpload((prev) => {
      const updated = [...prev];
      updated[index].title = title;
      return updated;
    });
  };

  const handleUploadComplete = (uploadedFiles) => {
    console.log("Upload Complete:", uploadedFiles);
    setFilesToUpload([]);
    setIsUploading(false);
    if (parentCallback) {
      parentCallback(uploadedFiles);
    }
    handleClose();
  };

  const handleProgressChange = (progress) => {
    setUploadProgress((prev) => {
      const newProgress = { ...prev, ...progress };
      const totalProgress = Object.values(newProgress).reduce(
        (acc, val) => acc + val,
        0
      ); // 🔥 Sum all percentages

      console.log("Total Progress Updated:", totalProgress); // ✅ Debugging

      return newProgress;
    });
  };

  const handleAddMoreFiles = () => {
    fileInputRef.current?.click();
  };

  const handleFileUploadComplete = () => {
    setUploadedCount((prev) => prev + 1);
  };

  const handleClose = () => {
    console.log("Closing upload dialog, resetting everything... 🔄");
    setOpen(false);
    setFilesToUpload([]);
    setIsUploading(false);
    setUploadedCount(0);
    setUploadProgress({});

    if (resetUploader) {
      console.log("Calling resetUploader function from UploaderV4 ✅");
      resetUploader(); // ✅ Call reset function from UploaderV4
    }
  };

  return (
    <>
      {/* Hidden File Input */}
      <input
        type="file"
        multiple={multiple}
        ref={fileInputRef}
        className="sr-only"
        onChange={handleFileSelection}
      />

      {/* File Picker Button */}
      <div
        className={TWclassNames(
          required ? "border-2 border-red-400" : "",
          "text-center bg-blue-400 hover:bg-blue-600 border-gray-300 px-5 py-5"
        )}
      >
        <label
          htmlFor={"file-upload"}
          className={TWclassNames(
            disabled ? "cursor-not-allowed" : "bg-white",
            "relative cursor-pointer rounded-md font-medium text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-400 focus-within:ring-offset-2"
          )}
        >
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="w-full h-full flex flex-row items-center justify-center"
          >
            <CameraIcon className="h-5 w-6 mr-2 text-white" />
            {!iconOnly && (
              <div className="flex-row">
                <span>Last opp dokument/ bilde</span>
              </div>
            )}
          </button>
        </label>
      </div>

      {/* Upload Dialog */}
      <PMDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Forhåndsvisning og opplasting"
      >
        {filesToUpload.length > 0 && (
          <>
            {filesToUpload.map((file, index) => (
              <div
                key={index}
                className="flex flex-col gap-3 py-4 bg-gray-100 rounded-xl px-4"
              >
                <img
                  src={file.previewUrl}
                  className="h-40 object-cover rounded"
                  alt="Preview"
                />
                <PMField
                  name="title"
                  type="text"
                  label="Tittel"
                  value={file.title}
                  onChange={(e) => handleTitleChange(index, e.target.value)}
                />
              </div>
            ))}

            {/* Progress Bar */}
            {isUploading && filesToUpload.length > 0 && (
              <div className="mt-4 w-full bg-gray-300 rounded h-4">
                <div
                  className="h-full bg-blue-500 rounded"
                  style={{
                    width: `${
                      (Object.values(uploadProgress).reduce(
                        (acc, val) => acc + val,
                        0
                      ) /
                        (filesToUpload.length * 100)) *
                      100
                    }%`,
                  }}
                />
              </div>
            )}
            {/* Upload Status */}
            {isUploading && (
              <p className="text-center mt-2 text-sm text-gray-700">
                Opplasting {uploadedCount + 1} av {filesToUpload.length}
              </p>
            )}

            {/* Upload Complete Checkmark */}
            {uploadedCount === filesToUpload.length && isUploading && (
              <div className="flex justify-center items-center mt-4 text-green-500">
                <CheckCircleIcon className="h-6 w-6" />
                <span className="ml-2 text-green-600 font-semibold">
                  Opplasting fullført
                </span>
              </div>
            )}

            {/* Add More Files Button */}
            <button
              type="button"
              className="mt-4 flex items-center justify-center w-full bg-blue-500 text-white p-2 rounded"
              onClick={handleAddMoreFiles}
            >
              <CameraIcon className="h-5 w-5 mr-2" /> Legg til flere bilder
            </button>

            {/* Upload Button */}
            <div className="flex justify-between mt-4">
              <button
                className="w-20 bg-gray-600 text-white p-2 rounded"
                onClick={() => handleClose()}
              >
                Steng
              </button>

              <button
                disabled={isUploading || !startUpload}
                className="w-20 p-2 rounded text-white bg-emerald-600 hover:bg-blue-500 disabled:bg-gray-400"
                onClick={() => {
                  console.log("startUpload:", startUpload); // 👀 Sjekk hva som logges
                  if (!isUploading && typeof startUpload === "function") {
                    setIsUploading(true);
                    setUploadedCount(0);
                    startUpload().then(() => setIsUploading(false));
                  } else {
                    console.error(
                      "startUpload is not a function or is undefined:",
                      startUpload
                    );
                  }
                }}
              >
                {isUploading ? "Laster opp..." : "Last opp"}
              </button>
            </div>
          </>
        )}
      </PMDialog>

      {/* Upload Handler */}
      <UploaderV4
        filesToUpload={filesToUpload}
        onUploadStart={(uploadFn) => {
          if (uploadFn && typeof uploadFn === "function") {
            console.log("Received valid upload function ✅:", uploadFn);
            setStartUpload(() => uploadFn);
          } else {
            console.error("Invalid upload function received:", uploadFn);
          }
        }}
        onUploadComplete={handleUploadComplete}
        onProgressChange={handleProgressChange}
        onFileUploadComplete={handleFileUploadComplete} // ✅ NEW
        onReset={setResetUploader} // ✅ Pass reset function
      />
    </>
  );
};
