import { useState } from "react";
import toast from "react-hot-toast";
import ProductSlideover from "../../products/product-slideover";
import ReportHistorySlideover from "../../service/reports/report-history-slideover";
import CustomerSystemDialog from "./system-dialog";
import { useDispatch } from "react-redux";
import { updateReportAsync } from "../../../../slices/service/reports";
import { dispatchWithToast } from "../../../Utils";

export const CustomerSystemSection = ({
  system,
  module,
  moduleParam,
  customer,
  onRefresh,
}) => {
  const [historySlideoverOpen, setHistorySlideoverOpen] = useState(false);
  const [productSlideoverOpen, setProductSlideoverOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnAttachSystem = (data) => {
    if (module === "report" && moduleParam) {
      dispatchWithToast(
        dispatch(
          updateReportAsync({ id: moduleParam, system_id: data?.value })
        ),
        (data) => onRefresh()
      );
    }
  };

  return (
    <>
      <section
        className="bg-white shadow sm:rounded-lg overflow-hidden"
        aria-labelledby="applicant-information-title"
      >
        <div className="">
          <div className="flex flex-col sm:flex-row justify-between px-4 py-5 sm:px-6 w-full">
            <div className="block w-[50%]">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Kundens anlegg
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {system?.displayTitle}
              </p>
            </div>
            <div className="flex mt-5 sm:mt-0">
              <div className="px-2 sm:py-2 w-full sm:w-auto mb-1 sm:mb-0">
                <button
                  type="button"
                  className="w-full sm:w-auto cursor-pointer rounded-md border border-transparent disabled:cursor-not-allowed disabled:bg-gray-400 bg-blue-500 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setDialogOpen(true)}
                >
                  Rediger
                </button>
              </div>

              <div className="px-2 sm:py-2 w-full sm:w-auto mb-1 sm:mb-0">
                <button
                  type="button"
                  className="w-full sm:w-auto cursor-pointer rounded-md border border-transparent disabled:cursor-not-allowed disabled:bg-gray-400 bg-yellow-500 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setHistorySlideoverOpen(true)}
                >
                  Historikk
                </button>
              </div>

              <div className="px-2 sm:py-2 w-full sm:w-auto mb-1 sm:mb-0">
                <button
                  type="button"
                  disabled={!system?.product_id}
                  className="w-full sm:w-auto cursor-pointer rounded-md border border-transparent disabled:cursor-not-allowed disabled:bg-gray-400 bg-yellow-500 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    if (system?.product_id) {
                      setProductSlideoverOpen(true);
                    } else {
                      toast.error("No product selected");
                    }
                  }}
                >
                  Reservedeler
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProductSlideover
        open={productSlideoverOpen}
        onClose={() => setProductSlideoverOpen(false)}
        view="spareparts"
        productId={system?.product?.id}
        module={module}
        moduleParam={moduleParam}
      />
      <ReportHistorySlideover
        open={historySlideoverOpen}
        onClose={() => setHistorySlideoverOpen(false)}
        systemId={system?.id}
      />
      <CustomerSystemDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        readOnly={true}
        selectedSystem={system?.id}
        customer={customer}
        onAttachSystem={handleOnAttachSystem}
        module={module}
        moduleParam={moduleParam}
      />
    </>
  );
};
