import { CheckBadgeIcon, PencilIcon } from "@heroicons/react/24/outline";
import { TWclassNames } from "../../../utils/Div";
import { status } from "../../../Utils";
import { useTranslation } from "react-i18next";

export const ServiceAgreementStatusColor = ({ serviceAgreement }) => {};

export const ServiceAgreementStatus = ({ serviceAgreement }) => {
  const { t } = useTranslation();

  return (
    <span
      className={TWclassNames(
        serviceAgreement?.status ? status[serviceAgreement?.status].class : "",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {serviceAgreement?.status ? (
        <span>
          {" "}
          {t(status[serviceAgreement?.status]?.label)}{" "}
          {serviceAgreement?.finished ? serviceAgreement?.finished_date : null}{" "}
        </span>
      ) : (
        <span className="bg-gray-100 inline-flex rounded-full px-2 text-xs font-semibold leading-5">
          None
        </span>
      )}
    </span>
  );
};

export const serviceAgreementDaysUntilNextService = ({ agreement }) => {
  const { days_until_next_service } = agreement;

  let object = {
    bgClass: "bg-green-200",
    days: days_until_next_service,
  };

  if (days_until_next_service < 5) {
    object.bgClass = "bg-red-200";
  } else if (days_until_next_service < 15) {
    object.bgClass = "bg-yellow-200";
  } else {
    object.bgClass = "bg-green-200";
  }

  return object;
};

export const ServiceAgreementNextServiceStatus = ({ serviceAgreement }) => {
  const { s, days_until_next_service } = serviceAgreement;

  return (
    <span
      className={TWclassNames(
        "bg-green-100 text-green-800",
        serviceAgreementDaysUntilNextService({ agreement: serviceAgreement })
          .bgClass,
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {days_until_next_service && days_until_next_service}
    </span>
  );
};

export const serviceAgreementStatuses = [
  { id: 0, name: "status", value: "draft", label: "Draft" },
  {
    id: 1,
    name: "status",
    value: "active",
    label: "Active",
    icon: CheckBadgeIcon,
  },
  {
    id: 2,
    name: "status",
    value: "canceled",
    label: "Canceled",
    icon: PencilIcon,
  },
  {
    id: 2,
    name: "status",
    value: "deleted",
    label: "Deleted",
    icon: PencilIcon,
  },
];

export const serviceAgreementIntervals = [
  { id: 4, name: "interval_id", value: 4, label: "Monthly" },
  {
    id: 1,
    name: "interval_id",
    value: 1,
    label: "Yearly (12 months)",
    icon: CheckBadgeIcon,
  },
  {
    id: 2,
    name: "interval_id",
    value: 2,
    label: "Half Yearly (6 months)",
    icon: CheckBadgeIcon,
  },
  {
    id: 3,
    name: "interval_id",
    value: 3,
    label: "Quarterly (3 months)",
    icon: CheckBadgeIcon,
  },
  {
    id: 9,
    name: "interval_id",
    value: 9,
    label: "Every 8 Months",
    icon: CheckBadgeIcon,
  },
  {
    id: 10,
    name: "interval_id",
    value: 10,
    label: "Every 10 Months",
    icon: CheckBadgeIcon,
  },
  {
    id: 5,
    name: "interval_id",
    value: 5,
    label: "Every 2 Years",
    icon: CheckBadgeIcon,
  },
  {
    id: 7,
    name: "interval_id",
    value: 7,
    label: "Every 3 Years",
    icon: CheckBadgeIcon,
  },
  {
    id: 8,
    name: "interval_id",
    value: 8,
    label: "Every 4 Years",
    icon: CheckBadgeIcon,
  },
  {
    id: 6,
    name: "interval_id",
    value: 6,
    label: "Every 5 Years",
    icon: CheckBadgeIcon,
  },
];

export const ServiceAgreementIntervalById = (id) => {
  return serviceAgreementIntervals.find((i) => i.id === id);
};

export const ServiceAgreementViewOptions = [
  {
    id: "SERVICE_SOON",
    value: "SERVICE_SOON",
    label: "Service snart (5 dager)",
  },
  {
    id: "ACTIVE",
    value: "ACTIVE",
    label: "Aktive",
  },
];
