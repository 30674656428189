import { useState } from "react";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import PMLinkCard from "../../../utils/PMLinkCard";
import ReportFinishDialog from "./report-finish-dialog";
import ServiceReportFinishModal from "./service-report-finish-modal";
import { TWclassNames } from "../../../utils/Div";
import ReportDocumentsDialog from "./report-documents-dialog";
import { DocumentUploadSectionV2 } from "../../Media/DocumentUploadSectionV2";
import { Link } from "react-router-dom";
import DocumentsDialog from "../../documents/documents-dialog";
import { DocumentUploadSectionV3 } from "../../Media/DocumentUploadSectionV3";
import { DocumentUploadSectionV4 } from "../../Media/DocumentUploadSectionV4";

export const ReportFinish = (props) => {
  const { report, isLoading } = props;

  const finishActions = [
    {
      label: "Ferdigstill rapport",
      description: "Write the report",
      path: "",
      action: () => setServiceReportFinishModalOpen(true),
      icon: DocumentIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
      show: true,
      completed: null,
    },
  ];
  const [finishReportDialogOpen, setFinishReportDialogOpen] = useState(false);

  const [serviceReportFinishModalOpen, setServiceReportFinishModalOpen] =
    useState(false);

  function openFinishReportDialog() {
    setFinishReportDialogOpen(true);
  }

  const canFinishReport = () => {
    const operations = report?.operations;

    if (!operations) return true;

    // Check if there is any errors with type "create_order" in report.operations
    if (operations) {
      const errors = operations.filter(
        (operation) =>
          operation.name === "finish_report" &&
          operation.can_be_completed === false
      );
      return errors.length > 0;
    }
  };

  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);

  const Section = () => (
    <section
      className="lg:max-w[7xl]"
      aria-labelledby="applicant-information-title"
    >
      <div className="bg-white shadow sm:rounded-lg">
        <div className="flex items-center px-4 py-5 sm:px-6 w-full">
          <div className="block w-[60%]">
            <h2
              id="applicant-information-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Rapporten
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 hidden sm:block">
              Fyll ut rapport, vareforbruk, variabler og sjekkliste <br />
            </p>
          </div>
          <div className="relative right-0 text-right w-[40%]">
            {/* <button
            onClick={() => setReportDialogOpen(true)}
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
          </button> */}
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
            <PMLinkCard actions={finishActions} cols={1} />
          </dl>
          {report?.system_id && report?.report?.length > 5 && (
            <dl className="col-span-1">
              <button
                onClick={() => openFinishReportDialog()}
                type="button"
                className="mt-2 p-2 w-full bg-green-400 rounded-md shadow-md font-semibold"
              >
                Finish report
              </button>
            </dl>
          )}
        </div>
      </div>
    </section>
  );

  const SectionV2 = () => (
    <section
      className="lg:max-w[7xl]"
      aria-labelledby="applicant-information-title"
    >
      <div className="bg-white shadow sm:rounded-lg">
        <div className="hidden sm:flex items-center px-4 py-5 sm:px-6 w-full">
          <div className="block w-[60%]">
            <h2
              id="applicant-information-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Rapporten
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 hidden sm:block">
              Fyll ut rapport, vareforbruk, variabler og sjekkliste <br />
            </p>
          </div>
        </div>

        <div className="grid sm:grid-cols-4 border-t border-gray-200">
          <dl className="sm:col-span-1">
            {/* <button
              className="min-h-full min-w-full bg-emerald-400 hover:bg-green-600 px-5 py-5"
              onClick={() => setServiceReportFinishModalOpen(true)}
            >
              Fyll ut rapport
            </button> */}

            <button className="min-h-full min-w-full bg-emerald-400 hover:bg-green-600 px-5 py-5">
              <Link
                to={`/dashboard/service/reports/${report?.id}/finish`}
                className="min-h-full min-w-full px-5 py-5"
              >
                Fyll ut rapport
              </Link>
            </button>
          </dl>
          <dl className="sm:col-span-1">
            <button
              onClick={() => openFinishReportDialog()}
              type="button"
              className={TWclassNames(
                canFinishReport()
                  ? "bg-gray-400 hover:bg-gray-600"
                  : "bg-green-400 hover:bg-green-600",
                "px-5 py-5 w-full"
              )}
            >
              Finish report
            </button>
          </dl>
          <dl className="sm:col-span-1">
            <button
              onClick={() => setDocumentsDialogOpen(true)}
              className="min-h-full text-center items-center min-w-full border border-transparent bg-blue-500 px-5 py-5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Se dokument & bilder
            </button>
          </dl>

          <dl className="sm:col-span-1">
            <DocumentUploadSectionV4
              module="report"
              moduleParam={report?.id}
              multiple={true}
            />
          </dl>
        </div>
      </div>
    </section>
  );
  if (isLoading) return <ResourceLoading />;

  return (
    <>
      {/* Description list*/}
      <SectionV2 />

      <ReportFinishDialog
        open={finishReportDialogOpen}
        onClose={() => setFinishReportDialogOpen(false)}
        selected={report}
        onRefresh={props.onRefresh}
        isLoading={isLoading}
      />

      <ServiceReportFinishModal
        open={serviceReportFinishModalOpen}
        onClose={() => setServiceReportFinishModalOpen(false)}
        selected={report}
        onRefresh={props.onRefresh}
      />

      <DocumentsDialog
        module="report"
        moduleParam={report?.id}
        open={documentsDialogOpen}
        onClose={() => setDocumentsDialogOpen(false)}
        selected={report}
        onRefresh={props.onRefresh}
      />
    </>
  );
};
